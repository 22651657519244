import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import WatchFilledIcon from '@atlaskit/icon/core/migration/eye-open-filled--watch-filled';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { WatchDialog } from '@confluence/watch-dialog';
import { MANAGE_WATCHER_TAB_TYPES } from '@confluence/manage-watcher-dialog';
import { WatchButtonPlaceholder } from '@confluence/watch-dialog/entry-points/WatchButtonPlaceholder';

import { PageCardContext } from '../../PageCardContext';
import { CustomAppearanceButton } from '../PresentationalComponents';
import { useMenuState, usePageCardAnalyticsEvents } from '../../hooks';

import { QuickActionsEnum } from './QuickActionsEnum';
import { ActionContainer } from './ActionContainer';

const i18n = defineMessages({
	watch: {
		id: 'page-card.quick-actions.watch.page',
		defaultMessage: 'Watch',
		description: 'Watch action in page card quick actions',
	},
	unwatch: {
		id: 'page-card.quick-actions.unwatch.page',
		defaultMessage: 'Unwatch',
		description: 'Unwatch action in page card quick actions',
	},
	watchLabel: {
		id: 'page-card.quick-actions.watch.aria-label',
		defaultMessage: 'Watch {title}',
		description:
			'Aria label for unfilled watch icon button which indicates that the page is unwatched & watches the page when clicked',
	},
	unwatchLabel: {
		id: 'page-card.quick-actions.unwatch.aria-label',
		defaultMessage: 'Unwatch {title}',
		description:
			'Aria label for filled watch icon button which indicates that the page is watched & unwatches the page when clicked',
	},
});

type WatchTriggerProps = {
	isWatching: boolean;
	isSelected: boolean;
	onClick: () => void;
	onMouseOver: () => void;
	triggerProps?: any;
};
export const WatchTrigger: FC<WatchTriggerProps> = ({
	isWatching,
	isSelected,
	triggerProps,
	onClick,
	onMouseOver,
}) => {
	const { createAnalyticsEvent } = usePageCardAnalyticsEvents();
	useMenuState(QuickActionsEnum.WatchButton, isSelected);
	const { type, appearance, title } = useContext(PageCardContext);
	const intl = useIntl();
	const compact = appearance === 'compact-list';
	const [Icon, label, ariaLabel] = isWatching
		? [
				WatchFilledIcon,
				intl.formatMessage(i18n.unwatch),
				intl.formatMessage(i18n.unwatchLabel, { title: title.text }),
			]
		: [
				WatchIcon,
				intl.formatMessage(i18n.watch),
				intl.formatMessage(i18n.watchLabel, { title: title.text }),
			];

	const handleWatchClick = useCallback(() => {
		onClick();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'content-watch-button',
				attributes: {
					contentType: type,
				},
			},
		}).fire();
	}, [onClick, createAnalyticsEvent, type]);

	return (
		<Tooltip content={label} position="top" hideTooltipOnClick>
			<div>
				<CustomAppearanceButton
					spacing={compact ? 'compact' : 'default'}
					appearance="subtle"
					onClick={handleWatchClick}
					onMouseOver={onMouseOver}
					isSelected={isSelected}
					aria-label={ariaLabel}
					iconBefore={<Icon label={label} />}
					{...(triggerProps || {})}
				/>
			</div>
		</Tooltip>
	);
};

export const WatchButton: FC = () => {
	const { id, appearance, isViewed } = useContext(PageCardContext);
	const compact = appearance === 'compact-list';

	return (
		<ActionContainer>
			{isViewed ? (
				<WatchDialog
					contentId={id}
					defaultTab={MANAGE_WATCHER_TAB_TYPES.PAGE}
					customTrigger={WatchTrigger}
					useShortcut={false}
					compact={compact}
				/>
			) : (
				<WatchButtonPlaceholder compact={compact} />
			)}
		</ActionContainer>
	);
};
