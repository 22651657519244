import type { FC, MouseEvent } from 'react';
import React, { useCallback, useContext, Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';

import StarUnfilledIcon from '@atlaskit/icon/core/star-unstarred';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { ErrorDisplay } from '@confluence/error-boundary';
import { StarSelectedIcon } from '@confluence/icons/entry-points/StarSelectedIcon';

import { PageCardContext } from '../../PageCardContext';
import { CustomAppearanceButton } from '../PresentationalComponents';
import { usePageCardAnalyticsEvents } from '../../hooks';

import { PageCardStarMutation, PageCardUnstarMutation } from './StarringMutations.graphql';
import { ActionContainer } from './ActionContainer';
import type {
	PageCardStar as StarMutationData,
	PageCardStarVariables as StarMutationVars,
} from './__types__/PageCardStarMutation';
import type {
	PageCardUnstar as UnstarMutationData,
	PageCardUnstarVariables as UnstarMutationVars,
} from './__types__/PageCardUnstarMutation';

const i18n = defineMessages({
	star: {
		id: 'page-card.star.content.tooltip.label',
		defaultMessage: 'Star',
		description:
			'label for unfilled star icon tooltip which indicates that the content is unstarred & stars the content when clicked',
	},
	unstar: {
		id: 'page-card.unstar.content.tooltip.label',
		defaultMessage: 'Unstar',
		description:
			'label for filled star icon tooltip which indicates that the content is starred & unstars the content when clicked',
	},
});

export const StarButton: FC = () => {
	const { id, type, isStarred, appearance } = useContext(PageCardContext);
	const intl = useIntl();
	const compact = appearance === 'compact-list';
	const { createAnalyticsEvent } = usePageCardAnalyticsEvents();

	const [star, { error: starError }] = useMutation<StarMutationData, StarMutationVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageCardStarMutation,
	);
	const [unstar, { error: unstarError }] = useMutation<UnstarMutationData, UnstarMutationVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageCardUnstarMutation,
	);

	const sendAnalyticsEvent = useCallback(
		(action: string) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action,
					actionSubject: 'favouritePage',
					attributes: {
						type,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, type],
	);

	const onStarClick = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			void star({
				variables: { id },
				optimisticResponse: {
					favouritePage: {
						__typename: 'FavouritePagePayload',
						content: {
							__typename: 'Content',
							id,
							metadata: {
								__typename: 'ContentMetadata',
								currentuser: {
									__typename: 'ContentMetadata_CurrentUserMetadataProvider_currentuser',
									favourited: {
										// @ts-ignore TS doesn't recognize __typename which is needed for mutation
										__typename: 'FavouritedSummary',
										isFavourite: true,
									},
								},
							},
						},
					},
				},
			});
			sendAnalyticsEvent('created');
		},
		[star, id, sendAnalyticsEvent],
	);

	const onUnstarClick = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			void unstar({
				variables: { id },
				optimisticResponse: {
					unfavouritePage: {
						__typename: 'UnfavouritePagePayload',
						content: {
							__typename: 'Content',
							id,
							metadata: {
								__typename: 'ContentMetadata',
								currentuser: {
									// @ts-ignore TS doesn't recognize __typename which is needed for mutation
									__typename: 'ContentMetadata_CurrentUserMetadataProvider_currentuser',
									favourited: null,
								},
							},
						},
					},
				},
			});
			sendAnalyticsEvent('deleted');
		},
		[unstar, id, sendAnalyticsEvent],
	);

	const tooltipLabel = isStarred ? intl.formatMessage(i18n.unstar) : intl.formatMessage(i18n.star);

	return (
		<Fragment>
			{starError && <ErrorDisplay error={starError} />}
			{unstarError && <ErrorDisplay error={unstarError} />}
			<Tooltip content={tooltipLabel} position="top" hideTooltipOnClick>
				<div>
					{isStarred ? (
						<CustomAppearanceButton
							id={`star-button-${id}`}
							spacing={compact ? 'compact' : 'default'}
							appearance="subtle"
							onClick={onUnstarClick}
							aria-label={intl.formatMessage(i18n.star)}
							aria-pressed={isStarred}
							aria-labelledby={`star-button-${id} content-title-${id}`}
							iconBefore={<StarSelectedIcon label={tooltipLabel} />}
							testId="page-unstar-button"
						/>
					) : (
						<ActionContainer>
							<CustomAppearanceButton
								id={`star-button-${id}`}
								spacing={compact ? 'compact' : 'default'}
								appearance="subtle"
								onClick={onStarClick}
								aria-label={intl.formatMessage(i18n.star)}
								aria-pressed={isStarred}
								aria-labelledby={`star-button-${id} content-title-${id}`}
								iconBefore={<StarUnfilledIcon label={tooltipLabel} />}
								testId="page-star-button"
							/>
						</ActionContainer>
					)}
				</div>
			</Tooltip>
		</Fragment>
	);
};
