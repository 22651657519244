import type { FC } from 'react';
import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import { Box, xcss } from '@atlaskit/primitives';

import { PageCardContext } from '../PageCardContext';

const i18n = defineMessages({
	likes: {
		id: 'page-card.social.likes',
		defaultMessage: 'likes',
		description: 'Icon to show how many people like a page.',
	},
	comments: {
		id: 'page-card.social.comments',
		defaultMessage: 'comments',
		description: 'Icons to show how many people commented on a page.',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SocialProofContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	color: token('color.text.subtle', N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div + div': {
		marginLeft: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SocialProofItem = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const separatorStyles = xcss({
	marginLeft: 'space.075',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Count = styled.div({
	font: token('font.heading.xxsmall'),
	marginLeft: token('space.025', '2px'),
});

type SocialProofProps = {
	isInlineAction?: boolean;
};

export const SocialProof: FC<SocialProofProps> = ({ isInlineAction = false }) => {
	const { likesCount, commentsCount } = useContext(PageCardContext);
	const intl = useIntl();
	return (
		<SocialProofContainer>
			{isInlineAction && (!!likesCount || !!commentsCount) && <Box xcss={separatorStyles}>•</Box>}
			{likesCount ? (
				<SocialProofItem>
					<LikeIcon label={intl.formatMessage(i18n.likes)} LEGACY_size="small" />
					<Count>{likesCount}</Count>
				</SocialProofItem>
			) : null}
			{commentsCount ? (
				<SocialProofItem>
					<CommentIcon label={intl.formatMessage(i18n.comments)} LEGACY_size="small" />
					<Count>{commentsCount}</Count>
				</SocialProofItem>
			) : null}
		</SocialProofContainer>
	);
};
